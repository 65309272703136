import { Box, Modal } from "@mui/material";
import React from "react";

const CategoryModal = ({CategorySlide, open}) => {
    return (
        <>
            
                
            
        </>
    );
}

export default CategoryModal;